import React, { Dispatch, FC, SetStateAction } from 'react'

import { PencilIcon } from '@heroicons/react/24/outline'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'

import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import { GoalMember } from '../../interfaces'

import TemplatesTypeIndicator from './TemplatesTypeIndicator'
interface TemplatesSidebarHeaderProps {
  onClickDelete: (id: number) => void
  onClickEdit: (id: number) => void
  editMode: boolean
  selectedTemplate: GoalMember | null
  isSaving: boolean
  formFieldsChanged: boolean
  setHandleFormSave: Dispatch<SetStateAction<boolean>>
  resetToTemplateList: () => void
}
const TemplatesSidebarHeader: FC<TemplatesSidebarHeaderProps> = ({
  onClickDelete,
  onClickEdit,
  selectedTemplate,
  editMode,
  isSaving,
  formFieldsChanged,
  setHandleFormSave,
  resetToTemplateList,
}) => {
  // Init Confirm
  const confirmDelete = useConfirm()

  // Set Confirm title
  const confirmTitle = (
    <p>
      Delete {selectedTemplate?.parentGoalId ? 'Milestone' : 'Template'}:{' '}
      <span className="capitalize text-indigo-600">
        {selectedTemplate?.goalTitle}
      </span>
    </p>
  )

  // Set Confirm description
  const confirmDescription = selectedTemplate?.parentGoalId
    ? 'Are you sure you want to delete this milestone?'
    : 'Are you sure you want to delete this template?'

  return (
    <>
      <div className="sticky top-0 z-10 flex items-center justify-between gap-2 border-b border-gray-200 bg-white p-4">
        {editMode ? (
          <div className="flex">
            <Tooltip title="Back to templates" placement="top">
              <div>
                <IconButton
                  className="mr-2 rounded-md text-gray-500 hover:!bg-gray-100"
                  onClick={() => {
                    resetToTemplateList()
                  }}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </IconButton>
              </div>
            </Tooltip>
            <div className="flex items-center gap-2 text-sm uppercase">
              <TemplatesTypeIndicator
                isMilestone={selectedTemplate?.parentGoalId !== null}
              />
            </div>
          </div>
        ) : (
          <Button
            size="small"
            variant="contained"
            fullWidth={false}
            className="px-3 py-1"
            onClick={() => {
              onClickEdit(selectedTemplate.id)
            }}
            startIcon={<PencilIcon className="h-4 w-4" />}
          >
            Edit Template
          </Button>
        )}
        <div className="flex">
          <Button
            variant="contained"
            className="mr-2"
            onClick={() => setHandleFormSave(true)}
            loading={isSaving}
            disabled={!formFieldsChanged}
            buttonDataTestId={`templates-save-button-enabled-${formFieldsChanged}`}
          >
            Save
          </Button>
          <Button
            disabled={isSaving}
            size="small"
            color="error"
            variant="outlined"
            fullWidth={false}
            onClick={() => {
              confirmDelete({
                title: confirmTitle,
                description: confirmDescription,
              }).then((isConfirmed) => {
                if (!isConfirmed) return
                onClickDelete(selectedTemplate.id)
              })
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  )
}
export default TemplatesSidebarHeader

import { FC, useEffect, useState } from 'react'

import { captureException } from '@sentry/browser'
import { PROD_CLIENT } from 'api/src/common/enums'
import {
  CreateImproverGoalMemberTemplateMutation,
  CreateImproverGoalMemberTemplateMutationVariables,
  DeleteImproverGoalTemplateMember,
  DeleteImproverGoalTemplateMemberVariables,
  UpdateImproverGoalTemplateMember,
  UpdateImproverGoalTemplateMemberVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import useACL from 'src/lib/hooks/ACL/useACL'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import { LearnerCourseWithStorageObject } from 'src/lib/interfaces'
import { useAuth } from 'src/Providers'

import { DateTypes, ProgressionTypes, StartEndTriggerTypes } from '../constants'
import { setTemplateOptions } from '../helpers'
import { FormOptions, GoalMember } from '../interfaces'

import {
  CREATE_GOAL_TEMPLATE_ITEM,
  DELETE_GOAL_TEMPLATE_ITEM,
  UPDATE_GOAL_TEMPLATE_ITEM,
} from './Queries/queries'
import TemplatesSidebar from './SideBar/TemplatesSidebar'
import { GoalTemplate } from './TemplatesCell'
import TemplatesTrackBuilder from './TemplatesTrackBuilder'
import TemplatesTrackList from './TemplatesTrackList'

// Set the template options as an easy selectable list
interface TemplatesProps {
  templates: GoalTemplate[]
  parentTemplates: GoalTemplate[]
  pathname: string
  templateId: number | undefined | null
  courseOptions: LearnerCourseWithStorageObject[]
  refetch: () => Promise<unknown>
}

const Templates: FC<TemplatesProps> = ({
  templates,
  parentTemplates,
  courseOptions,
  pathname,
  templateId,
  refetch,
}) => {
  const { currentUser } = useAuth()
  const confirmFieldChanges = useConfirm()
  const activeClientIsStafflink =
    PROD_CLIENT.STAFFLINK === currentUser.membershipData.clientId

  // Template Options - used for selecting and quick accessing info for template editing
  const templateOptions = setTemplateOptions(templates)

  // Selected Template Id - Allows for quick navigation between templates
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null,
  )

  const [formFieldsChanged, setFormFieldsChanged] = useState(false)
  const [handleFormSave, setHandleFormSave] = useState(false)

  // Selected template - Controls inputs for form fields
  const [selectedTemplate, setSelectedTemplate] = useState<GoalMember>(null)

  // Track Template - Displays on the track builder and only changes when parent level objective changes
  const [trackTemplate, setTrackTemplate] = useState(null)

  // BUTTON STATES
  const [isLoading, setIsLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)

  // FORM IS SAVING
  const [isSaving, setIsSaving] = useState(false)

  const resetToTemplateList = () => {
    if (formFieldsChanged) {
      // save the form then reset
      confirmFieldChanges({
        title: 'You have unsaved changes',
        description: 'Do you want to save?',
        confirmationText: 'Save and continue',
        cancellationText: 'Continue without saving',
      }).then((isConfirmed) => {
        if (!isConfirmed) {
          performResetActions()
          return
        }
        // save the goal
        setIsSaving(true)
        setHandleFormSave(true)
        // user clicked back button so waiting for save to complete before resetting
        // as the user clicked to go back to template list
        // I think this could be done better?
        setTimeout(() => {
          performResetActions()
        }, 2000)
      })
    } else {
      performResetActions()
    }
  }

  const performResetActions = () => {
    setFormFieldsChanged(false)

    setSelectedTemplateId(null)
    // timeout stops the form flashing into edit mode then back to empty
    setTimeout(() => {
      setEditMode(false)
    }, 100)
    history.pushState(null, '', '/app/goals/overview/templates')
  }

  const goToTemplate = (id: number) => {
    setSelectedTemplateId(id)
    history.pushState(null, '', '/app/goals/overview/templates/' + id)
  }

  const goToEditTemplate = (id: number) => {
    setSelectedTemplateId(id)
    history.pushState(null, '', '/app/goals/overview/templates/' + id + '/edit')
    setEditMode(true)
  }

  const updateTracktemplate = (parentGoalId: number) => {
    if (parentGoalId === null) {
      setTrackTemplate(selectedTemplate)
    } else {
      setTrackTemplate(templateOptions[selectedTemplate?.parentGoalId])
    }
  }

  // Update page route on passed through templateId
  useEffect(() => {
    // Check if the path name ends with edit
    const currentPathIsEditMode = pathname.endsWith('/edit')

    // Check if the template ID matches any found in the DB
    const pathTemplateIdMatchesTemplateOptions = templateOptions[templateId]

    // IF not a milestone - do the following
    // IF it matches - set it as selectedID
    // IF pathname is editmode - go to edit page
    // ELSE deselect and return to template list
    if (!pathTemplateIdMatchesTemplateOptions?.parentGoalId) {
      if (pathTemplateIdMatchesTemplateOptions) {
        if (currentPathIsEditMode) {
          goToEditTemplate(templateId)
        } else {
          goToTemplate(templateId)
        }
      } else {
        resetToTemplateList()
      }
    }
  }, [templateId])

  // Update the selected template
  useEffect(() => {
    if (selectedTemplateId) {
      setSelectedTemplate(templateOptions[selectedTemplateId])
    } else {
      setSelectedTemplate(null)
    }
    updateTracktemplate(selectedTemplate?.parentGoalId)
  }, [selectedTemplateId, templates, selectedTemplate])

  const [createGoalTemplateItemMutation] = useMutation<
    CreateImproverGoalMemberTemplateMutation,
    CreateImproverGoalMemberTemplateMutationVariables
  >(CREATE_GOAL_TEMPLATE_ITEM, {
    onCompleted: async (item) => {
      setIsLoading(false)
      await refetch()
      goToEditTemplate(item.createImproverGoalMember.id)
    },
    onError: (error) => {
      setIsLoading(false)

      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const createGoalTemplate = async (parentGoalId: number = null) => {
    setIsLoading(true)

    // Set defaults
    const defaultDateFormat = DateTypes.day
    const defaultProgressionFormat = ProgressionTypes.simple
    const defaultStartDateTrigger = StartEndTriggerTypes.objective
    const defaultDueDateTrigger = parentGoalId
      ? StartEndTriggerTypes.selfStart
      : StartEndTriggerTypes.objective
    const defaultDateNumber = parentGoalId ? 0 : 14
    const defaultTemplateTitle = parentGoalId
      ? 'Template Milestone'
      : 'Template Goal'

    const result = await createGoalTemplateItemMutation({
      variables: {
        adminEditMode: true,
        input: {
          goalTitle: defaultTemplateTitle,
          isTemplate: true,
          startValue: 0,
          targetValue: 0,
          parentGoalId: parentGoalId,
          startDateNumber: defaultDateNumber,
          startDateFormat: defaultDateFormat,
          startDateTrigger: defaultStartDateTrigger,
          dueDateNumber: defaultDateNumber,
          dueDateFormat: defaultDateFormat,
          dueDateTrigger: defaultDueDateTrigger,
          templateType: defaultProgressionFormat,
        },
      },
    })

    return result
  }

  const [deleteGoalItemMutation] = useMutation<
    DeleteImproverGoalTemplateMember,
    DeleteImproverGoalTemplateMemberVariables
  >(DELETE_GOAL_TEMPLATE_ITEM, {
    onCompleted: async (item) => {
      // IF there was a parentGoalId - return to parent
      // ELSE reset all
      if (item.deleteImproverGoalMember?.parentGoalId) {
        goToEditTemplate(item.deleteImproverGoalMember?.parentGoalId)
      } else {
        resetToTemplateList()
      }
      await refetch()
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const deleteGoalTemplate = async (id: number) => {
    // Delete template item
    const result = await deleteGoalItemMutation({
      variables: {
        id: id,
        adminEditMode: true,
      },
    })

    return result
  }

  const [updateGoalItemMutation] = useMutation<
    UpdateImproverGoalTemplateMember,
    UpdateImproverGoalTemplateMemberVariables
  >(UPDATE_GOAL_TEMPLATE_ITEM, {
    onCompleted: async () => {
      await refetch()
      setIsLoading(false)
      setIsSaving(false)
      toast.success('Template Saved')
    },
    onError: (error) => {
      setIsLoading(false)
      setIsSaving(false)
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const {
    AccessControlList,
    saveACL,
    deleteACL,
    isLoading: isACLLoading,
    inputChangedACL,
  } = useACL({
    resourceType: 'ImproverGoalMember',
    resourceId: selectedTemplateId,
    principalTypes: ['CLIENT'],
    contentPrivateDefault: false,
  })

  useEffect(() => {
    if (inputChangedACL) {
      setIsSaving(true)
      saveACL().then(() => {
        setIsSaving(false)
      })
    }
  }, [inputChangedACL, saveACL])

  const updateGoalTemplate = async (templateArgs: FormOptions) => {
    setIsSaving(true)

    const isGlobal =
      (activeClientIsStafflink && templateArgs?.isGlobal) || false

    const disablingIsGlobal =
      selectedTemplate.isGlobal && !templateArgs?.isGlobal
    const enablingIsGlobal =
      !selectedTemplate.isGlobal && templateArgs?.isGlobal

    const result = await updateGoalItemMutation({
      variables: {
        id: templateArgs.id,
        adminEditMode: true,
        input: {
          isGlobal,
          templateUserRoles: templateArgs?.templateUserRoles,
          goalTitle: templateArgs?.goalTitle,
          goalBody: templateArgs?.goalBody,
          templateType: templateArgs?.templateType,
          startDateNumber: templateArgs?.startDateNumber,
          startDateFormat: templateArgs?.startDateFormat,
          startDateTrigger: templateArgs?.startDateTrigger,
          startDateTriggerMilestoneId:
            templateArgs?.startDateTriggerMilestoneId || null,
          dueDateNumber: templateArgs?.dueDateNumber,
          dueDateFormat: templateArgs?.dueDateFormat,
          dueDateTrigger: templateArgs?.dueDateTrigger,
          dueDateTriggerMilestoneId:
            templateArgs?.dueDateTriggerMilestoneId || null,
          startValue: templateArgs?.startValue,
          targetValue: templateArgs?.targetValue,
          attachedLearnerItemId: templateArgs?.attachedLearnerItemId || null,
        },
      },
    })

    if (disablingIsGlobal) {
      // Fire and forget, don't wait for a response
      deleteACL().catch((error) => {
        captureException(error, {
          extra: { message: 'Error deleting Goal template ACL' },
          tags: { goalId: selectedTemplateId },
        })
        toast.error('Error updating Global permissions. Refresh the page')
      })
    } else if (enablingIsGlobal && !isACLLoading) {
      // Fire and forget, don't wait for a response
      saveACL().catch((error) => {
        captureException(error, {
          extra: { message: 'Error saving Goal template ACL' },
          tags: { goalId: selectedTemplateId },
        })
        toast.error('Error updating Global permissions. Refresh the page')
      })
    }
    setFormFieldsChanged(false)
    return result
  }

  return (
    <div className="flex h-full flex-row">
      <div className="w-full overflow-y-scroll p-6">
        {selectedTemplateId && trackTemplate && editMode ? (
          <TemplatesTrackBuilder
            trackTemplate={trackTemplate}
            selectedTemplateId={selectedTemplateId}
            setSelectedTemplateId={setSelectedTemplateId}
            isLoading={isLoading}
            createGoalTemplate={createGoalTemplate}
          />
        ) : (
          <TemplatesTrackList
            templates={parentTemplates}
            selectedTemplateId={selectedTemplateId}
            createGoalTemplate={createGoalTemplate}
            isLoading={isLoading}
            resetToTemplateList={resetToTemplateList}
            goToTemplate={goToTemplate}
          />
        )}
      </div>

      <TemplatesSidebar
        courseOptions={courseOptions}
        isSaving={isSaving}
        trackTemplate={trackTemplate}
        selectedTemplate={selectedTemplate}
        setSelectedTemplateId={setSelectedTemplateId}
        createGoalTemplate={createGoalTemplate}
        deleteGoalTemplate={deleteGoalTemplate}
        updateGoalTemplate={updateGoalTemplate}
        isLoading={isLoading}
        editMode={editMode}
        goToEditTemplate={goToEditTemplate}
        AccessControlList={AccessControlList}
        formFieldsChanged={formFieldsChanged}
        setFormFieldsChanged={setFormFieldsChanged}
        handleFormSave={handleFormSave}
        setHandleFormSave={setHandleFormSave}
        resetToTemplateList={resetToTemplateList}
      />
    </div>
  )
}

export default Templates
